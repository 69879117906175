import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
const Terms = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-8 py-20 text-justify">
        <h1 className="text-center text-[28px] font-bold">
          Үйлчилгээний нөхцөл
        </h1>
        <div className="leading-[35px] text-[18px]">
          <h3 className="font-bold mt-5 mb-2">Нэг. Ерөнхий нөхцөл</h3>
          <div>
            1.1. urtuu.com нь “Өртөө тек” ХХК /“Компани”/-ийн албан ёсны цахим
            хуудас бөгөөд Компанийн тухай болон Компанийн үзүүлж байгаа
            үйлчилгээний танилцуулга, үнийн мэдээллийг хэрэглэгч, үйлчлүүлэгчдэд
            хүргэх зорилготой. <br /> 1.2. Энэхүү Үйлчилгээний нөхцөл нь
            хэрэглэгчдэд мэдээлэл хүргэх, үйлчилгээ үзүүлэхтэй холбоотой
            харилцааг зохицуулна. <br />
            1.3. Хэрэглэгч үйлчилгээ захиалахаасаа өмнө энэхүү Үйлчилгээний
            нөхцөлтэй сайтар танилцах үүрэгтэй. <br />
            1.4. Цахим хуудаст байршуулсан лого, нэр зэрэг нь Компанийн өмч
            бөгөөд цахим хуудасны нэр, лого, бусад загварыг хуулах, олшруулах,
            дуурайх, өөр бусад хэлбэрээр ашиглахыг хориглоно.
          </div>
          <h3 className="font-bold mt-5 mb-2">
            Хоёр. Цахим хуудасны үйл ажиллагаа
          </h3>
          <div>
            2.1. Компани urtuu.com цахим хуудсаар дамжуулан хэрэглэгчдэд
            үйлчилгээний танилцуулга, үнэ тарифын мэдээллийг хүргэнэ. Цахим
            хуудас нь хэрэглэгчдэд нээлттэй, мэдээлэл авах боломжийг олгосон
            бүрдүүлнэ. <br /> 2.2. Компани urtuu.com цахим хуудсаар дамжуулан
            дараах үйлчилгээг хэрэглэгчдэд санал болгоно. Үүнд:
            <p className="pl-20">
              2.2.1. Хөдөлгөөн засал эмчилгээ, уламжлалт эмчилгээ, бариа засал.
              <br />
              2.2.2. Цэвэрлэгээ. <br /> 2.2.3. Хүүхэд харах. <br />
              2.2.4. Супермаркетаас хүнс хүргэлт.
            </p>
            2.3. Цахим хуудасны хэвийн ажиллагаа, түүний аюулгүй байдал,
            мэдээллийн нууцлалыг Компани бүрэн хариуцна. <br /> 2.4. Мэдээллийн
            нууцлалтай холбоотой харилцааг Нууцын бодлогод заасны дагуу
            зохицуулна. <br />
            2.4. Мэдээллийн нууцлалтай холбоотой харилцааг “Хувь хүний
            мэдээллийн нууцын тухай” хуулийн дагуу зохицуулна.
          </div>
          <h3 className="font-bold mt-5 mb-2">
            Гурав. Хэрэглэгчийн санал, хүсэлт, гомдол хүлээн авах, шийдвэрлэх
          </h3>
          <div>
            3.1. Хэрэглэгч үйлчилгээ болон вэбсайтын талаар санал, гомдол,
            хүсэлтээ чөлөөтэй илэрхийлэх, илгээх эрхтэй. <br /> 3.2. Хэрэглэгч
            нь өөрийн санал, хүсэлт, гомдлыг info@urtuu.mn хаягаар ирүүлнэ.
            <br /> 3.3. Хэрэглэгчээс ирүүлсэн гомдлыг судалж, хянасны үндсэн
            дээр шийдвэрлэж хариу мэдэгдэнэ. <br /> 3.4. Хэрэглэгчээс ирүүлсэн
            санал, хүсэлт, гомдол, бусад маргаантай асуудлыг энэхүү Үйлчилгээний
            нөхцөл, Хувь хүний мэдээллийн нууцын тухай болон болон Монгол Улсын
            бусад холбогдох хууль тогтоомжид заасны дагуу шийдвэрлэнэ.
          </div>
          <h3 className="font-bold mt-5 mb-2">Дөрөв. Бусад</h3>
          <div>
            4.1. Компани нь энэхүү Үйлчилгээний нөхцөлд өөрчлөлт оруулах эрхтэй
            бөгөөд өөрчлөлт оруулсан тохиолдолд тухай бүр urtuu.com цахим хуудас
            дээр нийтэд нээлттэй нийтлэх бөгөөд хэрэглэгчид Үйлчилгээний
            нөхцөлтэй танилцах үүрэг хүлээнэ. <br />
            4.2. Хэрэглэгчийг үйлчилгээ авах захиалга хийх үед мөрдөгдөж байсан
            үйлчилгээний нөхцөл үйлчилгээ үзүүлэх шатанд өөрчлөгдсөн тохиолдолд
            өмнөх нөхцөл нь тухайн үйлчилгээг үзүүлж дуусах хүртэлх хугацаанд
            дагаж мөрдөгдөнө.
          </div>
          <h3 className="font-bold mt-5 mb-2">Тав. Нууцлалын бодлого</h3>
          <div>
            5.1. Энэхүү Нууцлалын бодлогын нь urtuu.com цахим хуудасны үйл
            ажиллагаанд Захиалгын хуудас дахь Хэрэглэгчийн мэдээллийг ашиглах,
            нууцлалыг хадгалахтай холбоотой харилцааг зохицуулахад оршино.{" "}
            <br />
            5.2.{" "}
            <span>
              Нууцлалын бодлого нь Монгол Улсад мөрдөгдөж байгаа Хувь хүний
              мэдээллийн нууцлалын тухай болон Монгол Улсын бусад хууль журамд
              заасан нөхцөлд үндэслэнэ.
            </span>{" "}
            <br />
            5.3. Нууцлалын бодлого нь цахим хуудасны Үйлчилгээний нөхцөлийн
            салшгүй хэсэг бөгөөд Хэрэглэгчийн мэдээллийн нууцлалыг хадгалах
            баталгаа болно. <br />
            5.4. Хэрэглэгчийн Захиалгын хуудаст бөглөсөн овог, нэр, утасны
            дугаар, цахим шуудангийн хаяг, хаяг, бусад мэдээлэл болон захиалгын
            түүх цахим хуудаст хадгалагдана. <br />
            5.5. “Өртөө тек” ХХК нь Захиалгын хуудас дахь хэрэглэгчийн
            мэдээллийг зөвхөн үйлчилгээний захиалга авах, баталгаажуулах,
            үйлчилгээ үзүүлэх зорилгоор ашиглах бөгөөд бусад зорилгоор
            ашиглахгүй, гуравдагч этгээдэд задруулахгүй, дамжуулахгүй байх үүрэг
            хүлээнэ. <br />
            5.6. Хэрэглэгч urtuu.com цахим хуудаст хадгалагдсан өөрийн
            мэдээллийг устгахыг хүсвэл{" "}
            <a href="mailto:info@urtuu.mn" className="underline">
              info@urtuu.mn
            </a>{" "}
            хаягаар хандан устгуулэх эрхтэй.
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;

export const Head = () => (
  <SEO
    title={`Үйлчилгээний нөхцөл`}
    description={`Үйлчилгээний нөхцөл болон Нууцлалын бодлого`}
  />
);
